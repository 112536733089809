import { BarterOfferRequestStatusEnum } from '@prisma/client'
import { type Params, useFormAction, useNavigation } from '@remix-run/react'
import { clsx, type ClassValue } from 'clsx'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSpinDelay } from 'spin-delay'
import { extendTailwindMerge } from 'tailwind-merge'
import { type ButtonProps } from '../components/ui/radixUiTheme/Button.tsx'
import { extendedTheme } from './extended-theme.ts'

export function getUserImgSrc(imageId?: string | null) {
	return imageId ? `/api/user-images/${imageId}` : '/img/user.png'
}

export function getNoteImgSrc(imageId: string) {
	return `/api/note-images/${imageId}`
}

export function getCustomerLocationImgSrc(imageId: string) {
	return `/api/customer-location-images/${imageId}`
}

export function getEquipmentImgSrc(imageId: string) {
	return `/api/equipment-images/${imageId}`
}

export function getErrorMessage(error: unknown) {
	if (typeof error === 'string') return error
	if (
		error &&
		typeof error === 'object' &&
		'message' in error &&
		typeof error.message === 'string'
	) {
		return error.message
	}
	console.error('Unable to get error message for error', error)
	return 'Unknown Error'
}

function formatColors() {
	const colors = []
	for (const [key, color] of Object.entries(extendedTheme.colors)) {
		if (typeof color === 'string') {
			colors.push(key)
		} else {
			const colorGroup = Object.keys(color).map(subKey =>
				subKey === 'DEFAULT' ? '' : subKey,
			)
			colors.push({ [key]: colorGroup })
		}
	}
	return colors
}

const customTwMerge = extendTailwindMerge<string, string>({
	extend: {
		theme: {
			colors: formatColors(),
			borderRadius: Object.keys(extendedTheme.borderRadius),
		},
		classGroups: {
			'font-size': [
				{
					text: Object.keys(extendedTheme.fontSize),
				},
			],
		},
	},
})

/*
	Used to combine multiple class strings or objects into one
*/
export function cn(...inputs: ClassValue[]) {
	return customTwMerge(clsx(inputs))
}

export function getDomainUrl(request: Request) {
	const host =
		request.headers.get('X-Forwarded-Host') ??
		request.headers.get('host') ??
		new URL(request.url).host
	const protocol = host.includes('localhost') ? 'http' : 'https'
	return `${protocol}://${host}`
}

export function getReferrerRoute(request: Request) {
	// spelling errors and whatever makes this annoyingly inconsistent
	// in my own testing, `referer` returned the right value, but 🤷‍♂️
	const referrer =
		request.headers.get('referer') ??
		request.headers.get('referrer') ??
		request.referrer
	const domain = getDomainUrl(request)
	if (referrer?.startsWith(domain)) {
		return referrer.slice(domain.length)
	} else {
		return '/'
	}
}

/**
 * Merge multiple headers objects into one (uses set so headers are overridden)
 */
export function mergeHeaders(
	...headers: Array<ResponseInit['headers'] | null | undefined>
) {
	const merged = new Headers()
	for (const header of headers) {
		if (!header) continue
		for (const [key, value] of new Headers(header).entries()) {
			merged.set(key, value)
		}
	}
	return merged
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
	...headers: Array<ResponseInit['headers'] | null | undefined>
) {
	const combined = new Headers()
	for (const header of headers) {
		if (!header) continue
		for (const [key, value] of new Headers(header).entries()) {
			combined.append(key, value)
		}
	}
	return combined
}

/**
 * Combine multiple response init objects into one (uses combineHeaders)
 */
export function combineResponseInits(
	...responseInits: Array<ResponseInit | null | undefined>
) {
	let combined: ResponseInit = {}
	for (const responseInit of responseInits) {
		combined = {
			...responseInit,
			headers: combineHeaders(combined.headers, responseInit?.headers),
		}
	}
	return combined
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
	formAction,
	formMethod = 'POST',
	state = 'non-idle',
}: {
	formAction?: string
	formMethod?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
	state?: 'submitting' | 'loading' | 'non-idle'
} = {}) {
	const contextualFormAction = useFormAction()
	const navigation = useNavigation()
	const isPendingState =
		state === 'non-idle'
			? navigation.state !== 'idle'
			: navigation.state === state
	return (
		isPendingState &&
		navigation.formAction === (formAction ?? contextualFormAction) &&
		navigation.formMethod === formMethod
	)
}

/**
 * This combines useSpinDelay (from https://npm.im/spin-delay) and useIsPending
 * from our own utilities to give you a nice way to show a loading spinner for
 * a minimum amount of time, even if the request finishes right after the delay.
 *
 * This avoids a flash of loading state regardless of how fast or slow the
 * request is.
 */
export function useDelayedIsPending({
	formAction,
	formMethod,
	delay = 400,
	minDuration = 300,
}: Parameters<typeof useIsPending>[0] &
	Parameters<typeof useSpinDelay>[1] = {}) {
	const isPending = useIsPending({ formAction, formMethod })
	const delayedIsPending = useSpinDelay(isPending, {
		delay,
		minDuration,
	})
	return delayedIsPending
}

function callAll<Args extends Array<unknown>>(
	...fns: Array<((...args: Args) => unknown) | undefined>
) {
	return (...args: Args) => fns.forEach(fn => fn?.(...args))
}

/**
 * Use this hook with a button and it will make it so the first click sets a
 * `doubleCheck` state to true, and the second click will actually trigger the
 * `onClick` handler. This allows you to have a button that can be like a
 * "are you sure?" experience for the user before doing destructive operations.
 */
export function useDoubleCheck() {
	const [doubleCheck, setDoubleCheck] = useState(false)

	function getButtonProps(props?: ButtonProps) {
		const onBlur: React.ButtonHTMLAttributes<HTMLButtonElement>['onBlur'] =
			() => setDoubleCheck(false)

		const onClick: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'] =
			doubleCheck
				? undefined
				: e => {
						e.preventDefault()
						setDoubleCheck(true)
					}

		const onKeyUp: React.ButtonHTMLAttributes<HTMLButtonElement>['onKeyUp'] =
			e => {
				if (e.key === 'Escape') {
					setDoubleCheck(false)
				}
			}

		return {
			...props,
			onBlur: callAll(onBlur, props?.onBlur),
			onClick: callAll(onClick, props?.onClick),
			onKeyUp: callAll(onKeyUp, props?.onKeyUp),
		}
	}

	return { doubleCheck, getButtonProps }
}

/**
 * Simple debounce implementation
 */
function debounce<Callback extends (...args: Parameters<Callback>) => void>(
	fn: Callback,
	delay: number,
) {
	let timer: ReturnType<typeof setTimeout> | null = null
	return (...args: Parameters<Callback>) => {
		if (timer) clearTimeout(timer)
		timer = setTimeout(() => {
			fn(...args)
		}, delay)
	}
}

/**
 * Debounce a callback function
 */
export function useDebounce<
	Callback extends (...args: Parameters<Callback>) => ReturnType<Callback>,
>(callback: Callback, delay: number) {
	const callbackRef = useRef(callback)
	useEffect(() => {
		callbackRef.current = callback
	})
	return useMemo(
		() =>
			debounce(
				(...args: Parameters<Callback>) => callbackRef.current(...args),
				delay,
			),
		[delay],
	)
}

export async function downloadFile(url: string, retries: number = 0) {
	const MAX_RETRIES = 3
	try {
		const response = await fetch(url)
		if (!response.ok) {
			throw new Error(`Failed to fetch image with status ${response.status}`)
		}
		const contentType = response.headers.get('content-type') ?? 'image/jpg'
		const blob = Buffer.from(await response.arrayBuffer())
		return { contentType, blob }
	} catch (e) {
		if (retries > MAX_RETRIES) throw e
		return downloadFile(url, retries + 1)
	}
}

function convertToEnum(value: string): BarterOfferRequestStatusEnum {
	if (value in BarterOfferRequestStatusEnum) {
		return BarterOfferRequestStatusEnum[
			value as keyof typeof BarterOfferRequestStatusEnum
		]
	} else {
		throw new Error(`Invalid status value: ${value}`)
	}
}

/**
 * Get a message from a form data object and throw an error if it's not found
 * @param formData  The form data object
 * @param whatToGet  The key to get from the form data object
 * @param error The error message to throw if the key is not found
 * @returns The message from the form data object
 */
export function getFormDataOrErrorOut(
	formData: FormData,
	whatToGet: string,
	error?: string,
): string | BarterOfferRequestStatusEnum {
	// Get the item from form data
	const itemToGet = formData.get(whatToGet)

	// Check if the item is null or undefined
	if (itemToGet === null || itemToGet === undefined) {
		throw new Error(error || `No ${whatToGet} found in form data.`)
	}

	const itemToGetString = itemToGet.toString()

	// Special case: if whatToGet is 'status', return the enum
	if (whatToGet === 'status') {
		return convertToEnum(itemToGetString)
	}

	// For all other cases, return the item as a string
	return itemToGetString
}

/**
 * Converts an ISO 8601 date string to a human-readable format.
 * @param {string} isoString - The ISO 8601 date string to convert.
 * @returns {string} A formatted, human-readable date string.
 */
export function formatDate(isoString: string) {
	const date = new Date(isoString)

	// Options for formatting
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		timeZoneName: 'short',
	}

	return date.toLocaleString('en-US', options)
}

export function getFormData(formData: FormData, whatToGet: string) {
	const itemToGet = formData.get(whatToGet)?.toString()
	return itemToGet
}

export function getParamOrErrorOut(
	params: Params<string>,
	object: string,
	error?: string,
) {
	const paramToGet = params[object]
	if (!paramToGet) {
		throw new Error(error || `No ${params} found in URL.`)
	}
	return paramToGet
}

export function getParam(params: Params<string>, object: string) {
	const paramToGet = params[object]
	return paramToGet
}

/**
 * Get the Current Window Size
 * @returns The window size
 */
export function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		width: 0,
	})

	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			// Set window width to state
			setWindowSize({
				width: window.innerWidth,
			})
		}

		// Add event listener
		window.addEventListener('resize', handleResize)

		// Call handler right away so state gets updated with initial window size
		handleResize()

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize)
	}, []) // Empty array ensures that effect is only run on mount and unmount

	return windowSize
}

/**
 * Returns the string in kebab-case
 * @param string
 * @returns
 */
export const toKebabCase = (string: string) =>
	string
		.replace(/([a-z])([A-Z])/g, '$1-$2')
		.replace(/[\s_]+/g, '-')
		.toLowerCase()

/**
 * Returns the string in camelCase
 * @param string
 * @returns
 */
export const toCamelCase = (string: string) =>
	string
		.replace(/-([a-z])/g, g => g[1].toUpperCase())
		.replace(/[\s_]+/g, '')
		.toLowerCase()

/**
 * Returns the string in Title Case
 * @param string
 * @returns
 */
export const toTitleCase = (string: string) =>
	string.replace(
		/\w\S*/g,
		txt => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase(),
	)

/**
 * Returns the string in PascalCase
 * @param string
 * @returns
 */
export const toPascalCase = (string: string) =>
	string
		.replace(/(\w)(\w*)/g, (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase())
		.replace(/[\s_]+/g, '')

/**
 * Returns the string in snake_case
 * @param string
 * @returns
 */
export const toSnakeCase = (string: string) =>
	string
		.replace(/([a-z])([A-Z])/g, '$1_$2')
		.replace(/[\s-]+/g, '_')
		.toLowerCase()

/**
 * Returns the string in CONSTANT_CASE
 * @param string
 * @returns
 */
export const toConstantCase = (string: string) =>
	string
		.replace(/([a-z])([A-Z])/g, '$1_$2')
		.replace(/[\s-]+/g, '_')
		.toUpperCase()

/**
 * Returns the string in SentenceCase
 * @param string
 * @returns
 */
export const toSentenceCase = (str: string) => {
	// Replace underscores, hyphens, or camel case with a space
	let formatted = str
		.replace(/[_-]+/g, ' ')
		.replace(/([a-z0-9])([A-Z])/g, '$1 $2')

	// Split the string into words, capitalize the first letter of each word, then join them back together
	formatted = formatted
		.toLowerCase()
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')

	return formatted
}
